import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import Select from 'react-select'

const LINKS = [
  { label: 'Home', url: '/' },
  { label: 'About', url: '/#about' },
  { label: 'Products', url: '/products' },
  { label: 'Why Us?', url: '/#why-us' },
  { label: 'Contact', url: '/contact' },
]

const Header = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(true)
  const [openMenu, setOpenMenu] = useState(false)
  const data = useStaticQuery(graphql`
    {
      allContentfulProduct {
        nodes {
          id
          name
          category
          description
          body {
            raw
          }
          featureImage {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 1000
            )
            resize {
              src
            }
          }
        }
      }
    }
  `)
  const products = data.allContentfulProduct.nodes

  const options = products.map((p) => ({ label: p.name, value: p.name }))

  useEffect(() => {
    const setLogoHeight = () => {
      if (window.innerWidth > 750) {
        setIsSmallScreen(false)
      } else {
        setIsSmallScreen(true)
      }
    }
    window.addEventListener('resize', setLogoHeight)

    setLogoHeight()

    return () => {
      window.removeEventListener('resize', setLogoHeight)
    }
  }, [])

  useEffect(() => {
    const el = document.querySelector('#header-nav-mobile')
    el.style.display = 'flex'
    el.style.height = openMenu ? '180px' : '0'
  }, [openMenu])

  const CustomOption = ({ isDisabled, ...rest }) => {
    return !isDisabled ? (
      <SearchOption>
        <Link to={`/product/${rest.data.value}`}>{rest.data.value}</Link>
      </SearchOption>
    ) : null
  }

  return (
    <>
      <Wrapper>
        <Container>
          <Logo>
            <Link to="/">
              {isSmallScreen ? (
                <StaticImage src="../images/r.png" alt="" height={40} />
              ) : (
                <StaticImage src="../images/r.png" alt="" height={70} />
              )}
              <span>Ronak Electrical and Electronics</span>
            </Link>
          </Logo>
          <div className="select">
            <Select
              options={options}
              components={{ Option: CustomOption }}
              placeholder="Search products..."
            />
          </div>
          <MenuIcon onClick={() => setOpenMenu(!openMenu)} openMenu={openMenu}>
            <span></span>
            <span></span>
            <span></span>
          </MenuIcon>
          <Nav>
            {LINKS.map((link, i) => (
              <NavItem key={i}>
                <Link to={`${link.url}`}>{link.label}</Link>
              </NavItem>
            ))}
          </Nav>
        </Container>

        <MobileNav id="header-nav-mobile">
          {LINKS.map((link, i) => (
            <NavItem key={i}>
              <Link to={`${link.url}`} onClick={(e) => setOpenMenu(false)}>
                {link.label}
              </Link>
            </NavItem>
          ))}
        </MobileNav>
      </Wrapper>
    </>
  )
}

export default Header

const Wrapper = styled.header`
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 40%);
  position: sticky;
  top: 34px;
  background: white;
  z-index: 999;

  .select {
    min-width: 220px;
    text-transform: capitalize;
  }

  @media (max-width: 400px) {
    top: 30px;
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 48px;
  max-width: 1300px;
  margin: auto;
  @media (min-width: 750px) {
    height: 70px;
    padding: 0 30px;
    .select {
      margin-left: auto;
      min-width: 300px;
    }
  }
`

const Logo = styled.div`
  font-size: 20px;
  margin: 0;
  a {
    text-decoration: none;
    color: #7a5d00;
    display: flex;
    align-items: center;
  }

  @media (max-width: 1200px) {
    span {
      display: none;
    }
  }
`

const Nav = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  height: 100%;
  @media (max-width: 750px) {
    display: none;
    flex-direction: column;
  }
`
const MobileNav = styled.ul`
  list-style: none;
  flex-direction: column;
  overflow: hidden;
  transition: 0.3s;
  margin: 0;
  padding: 0;
  display: none;
  @media (min-width: 750px) {
    display: none;
  }
`

const NavItem = styled.li`
  margin: 0 10px;
  & > a {
    padding: 3px;
    height: 100%;
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    text-transform: capitalize;
    transition: 0.3s;
    border-bottom: 3px solid white;
    @media (max-width: 750px) {
      padding: 5px;
    }
    &:hover {
      border-bottom: 3px solid black;
    }
  }
`

const MenuIcon = styled.div`
  display: none;
  width: 27px;
  height: 27px;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 750px) {
    display: flex;
  }

  & > span {
    display: block;
    background: black;
    height: 3px;
    width: 100%;
    border-radius: 1px;
    transition: 0.3s;
  }

  ${(props) =>
    props.openMenu &&
    css`
      span:nth-of-type(1) {
        transform: rotate(-45deg) translate(-5px, 8px);
      }

      span:nth-of-type(2) {
        opacity: 0;
      }

      span:nth-of-type(3) {
        transform: rotate(45deg) translate(-5px, -8px);
      }
    `}
`

const SearchOption = styled.div`
  padding: 5px;
`
