import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Footer = () => (
  <Wrapper>
    <Container>
      <Grid>
        <About>
          <h6>About Us</h6>
          <p>
            Established In the year 2017 at Faridabad, Haryana, we{' '}
            <strong>Ronak Electrical & Electronics</strong> are as Sole
            Proprietorship based firm, engaged as the foremost Authorized
            Manufacturing And Trader Dealer of Stabilizer Control Card,Single
            Phase Stabilizers, Single Phase Servo Stabilizer and many more.
          </p>
        </About>

        <Nav>
          <h6>Navigation</h6>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/#about">About Us</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </Nav>

        <Contact>
          <h6>Contact Us</h6>
          <ul>
            <li>Mr. Prem Verma</li>
            <li>Address</li>
            <li>+91-9810478208</li>
            <li>ronakelectrical98@gmail.com</li>
          </ul>
        </Contact>
      </Grid>
      <Digi>
        Made with <span>❤️</span> ️by{' '}
        <a href="https://digivats.com">DigiVats</a>
      </Digi>
    </Container>
  </Wrapper>
)

export default Footer

const Wrapper = styled.footer`
  background: black;
  * {
    color: white;
  }
`

const Container = styled.div`
  max-width: 1200px;
  padding: 0 10px 50px;
  margin: auto;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    h6 {
      margin-bottom: 20px;
    }
  }
`

const About = styled.div`
  h6 {
    font-size: 20px;
    max-width: 120px;
    padding-bottom: 5px;
    border-bottom: 1px solid ${({ theme }) => theme.color.p};
  }
  p {
    max-width: 500px;
    color: rgb(256 256 256 / 70%);
  }
  a,
  li {
    color: rgb(256 256 256 / 70%);
    &:hover {
      color: white;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 0 0 5px 0;
    }
  }
`

const Nav = styled(About)``

const Contact = styled(About)``

const Digi = styled.p`
  text-align: center;
  margin: 30px 0 0;
  a,
  span {
    color: #ec3b5d;
  }
`
