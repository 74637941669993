import React from 'react'
import './variables.css'
import './global.css'
import Seo from './seo'
import Header from './header'
import Toprow from './toprow'
import Footer from './footer'
import styled, { ThemeProvider } from 'styled-components'
import AOS from 'aos'
import 'aos/dist/aos.css'

const theme = {
  color: {
    p: '#FEC302',
  },
}

const Layout = ({ children }) => {
  React.useEffect(() => {
    AOS.init({
      offset: 30,
    })
  })

  return (
    <ThemeProvider theme={theme}>
      <Seo />
      <Wrapper>
        <Toprow />
        <Header />
        <main>{children}</main>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  )
}

export default Layout

const Wrapper = styled.div`
  max-width: 1500px;
  margin: auto;
`
